import React, { useEffect, useState, useContext } from "react";
import {
  FilterSelect,
  FilterText,
  LinkPager,
  Loader,
  PageContainer,
  StyledTD,
  LongBreadCrumb,
  PHIHider,
  StyledButton,
  NewPatientModal,
} from ".";
import { json, useNavigate, useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import {
  api,
  constants,
  filterHelpers,
  UserContext,
  helpers,
  dateHelpers,
  properCaseText,
} from "../utils";
import { Badge, Col, Row, Table, Tooltip } from "reactstrap";
import { VscWarning } from "react-icons/vsc";
import classNames from "classnames";
import { BsPersonFill } from "react-icons/bs";
import keys from "lodash/keys";
import forEach from "lodash/forEach";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { FiEdit } from "react-icons/fi";
import { useDebugConsole } from "../hooks";
import { each, map, reject } from "lodash/collection";
import { MdClear } from "react-icons/md";
import { useCookies } from "react-cookie";

const PAGE_SIZE = 25;

const Link = (props) => (
  <PaginationItem>
    <PaginationLink onClick={props.callBack}>{props.linkText}</PaginationLink>
  </PaginationItem>
);

export default function MemberManagement(props) {
  const userCtx = useContext(UserContext);
  const location = useLocation();

  let { type, clientId, campaignId, caseId, participantId } = useParams();
  clientId = clientId ? parseInt(clientId, 10) : null;
  participantId = participantId ? participantId : null;

  const [client, setClient] = useState(null);
  const [currentParticipants, setCurrentParticipants] = useState(null);
  const [totalCount, setTotalCount] = useState(0);

  const [activeOnly, setActiveOnly] = useState(true);
  const [startRecordNumber, setStartRecordNumber] = useState(0);
  const [endRecordNumber, setEndRecordNumber] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  //const [previousLinks, setPreviousLinks] = useState([]);
  //const [nextLink, setNextLink] = useState(null);
  //const [currentLink, setCurrentLink] = useState(null);
  const [participantInfo, setParticipantInfo] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const cookieName = `${constants.localStorageKeys.patientSavedSearch}-${clientId}`;
  const [cookies, setCookie, removeCookie] = useCookies();
  const [savedSearchResults, setSavedSearchResults] = useState(() => {
    let foundCookie = cookies[cookieName];
    return foundCookie;
  });
  const navigate = useNavigate();

  useEffect(() => {
    updateFilters(savedSearchResults);
  }, [savedSearchResults]);

  function splitCoverageList(participant) {
    let coverages = participant.coverages;
    if (!coverages || !coverages.length) return participant;
    coverages = _.filter(
      coverages,
      (c) =>
        !_.some(
          constants.COVERAGE_REJECT_LIST,
          (crl) => crl.toLowerCase() === c.name.toLowerCase()
        )
    );
    console.debug(coverages);
    let medical, vision, dental;
    [vision, medical] = _.partition(coverages, (c) =>
      _.some(
        constants.VISION_COVERAGE_TYPES,
        (v) => v === (c.type ? c.type.toLowerCase() : "")
      )
    );
    [dental, medical] = _.partition(medical, (c) =>
      _.some(
        constants.DENTAL_COVERAGE_TYPES,
        (v) => v === (c.type ? c.type.toLowerCase() : "")
      )
    );
    medical = _.orderBy(medical, [(x) => Date.parse(x.startOn)], ["desc"]);
    vision = _.orderBy(vision, [(x) => Date.parse(x.startOn)], ["desc"]);
    dental = _.orderBy(dental, [(x) => Date.parse(x.startOn)], ["desc"]);
    participant.medical = _.find(medical, ["type", "HIP"]);
    participant.vision = _.first(vision);
    participant.dental = _.first(dental);
    return participant;
  }

  function updateFilters(savedResults) {
    if (!savedResults) return;
    let newFilters = savedResults.filters.slice();
    // each(objKeys, (key) => {
    //   let savedFilterValue = savedResults[key];
    //   if (!savedFilterValue) return;
    //   newFilters.push({ filterName: key, value: savedResults[key] });
    // });
    if (newFilters.length > 0) {
      setFilters(newFilters);
      refreshData(1, newFilters);
    }
  }

  //Should probably only do this on a successful search
  function saveSearchResultsLocally() {
    removeCookie(cookieName, { path: location.pathname });
    // localStorage.removeItem(constants.localStorageKeys.patientSavedSearch);
    if (filters.length) {
      const filtersToSave = filters.slice();
      saveSearchCookie({ filters: filtersToSave });
    }
  }

  function saveSearchCookie(data) {
    setCookie(cookieName, data, {
      path: location.pathname,
      domain: window.location.hostname,
      maxAge: 60 * 5, //5 minutes
      expires: dateHelpers.addMinutesToCurrentDateTime(5),
      sameSite: "strict",
    });
  }

  function resolvePatientIdList() {
    return currentParticipants?.length
      ? _.chain(currentParticipants)
          .map((cp) =>
            cp.patientReferences?.length ? cp.patientReferences[0] : ""
          )
          .join(", ")
          .value()
      : null;
  }

  useEffect(() => {
    if (pageNumber <= 0) return;
    refreshData(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (!currentParticipants || !currentParticipants.length) return;
    api.logData({
      eventType: constants.LOG_EVENT_TYPES.PATIENT_SEARCH,
      severity: constants.LOG_SEVERITIES.INFO,
      message: `Client members screen accessed.`,
      clientName: client ? client.name : null,
      patientId: resolvePatientIdList(),
      pHIFlag: false,
      resourceList: [],
    });
  }, [currentParticipants]);

  useEffect(() => {
    if (!clientId) return;
    getClient().then((res) => {
      const { client } = res;
      if (!client) return;
      setClient(client);
    });
  }, [clientId]);

  useEffect(() => {
    if (!participantInfo) return;
    api.logData({
      eventType: constants.LOG_EVENT_TYPES.PHI_TOGGLE,
      severity: constants.LOG_SEVERITIES.INFO,
      message: `Member list PHI toggle switched ${
        userCtx.showSsn ? "on" : "off"
      }`,
      clientName: null,
      patientId: resolvePatientIdList(),
      pHIFlag: userCtx.showSsn,
      resourceList: null,
    });
  }, [userCtx.showSsn]);

  function refreshData(newPageNumber, quickFilters) {
    if (loading || !clientId) return;
    setLoading(true);
    let apiCalls = [];
    if (!client) {
      apiCalls.push(getClient());
    }
    apiCalls.push(getParticipants(newPageNumber, quickFilters));
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        if (aggResults.client) {
          setClient(aggResults.client);
        }
        if (aggResults.participants) {
          aggResults.participants.items = _.map(
            aggResults.participants.items,
            splitCoverageList
          );
          setCurrentParticipants(aggResults.participants.items);
          setTotalCount(aggResults.participants.totalCount);
          let pageCount = aggResults.participants.totalCount / 25.0;
          if (pageCount % 1 === 0) {
            pageCount = parseInt(pageCount);
          } else {
            pageCount = parseInt(pageCount);
            pageCount++;
          }
          setTotalPageCount(pageCount);
          if (pageNumber !== newPageNumber) {
            setPageNumber(newPageNumber);
          }
          const start = (newPageNumber - 1) * 25 + 1;
          setStartRecordNumber(start);
          const maxEndRecordCount = start + PAGE_SIZE - 1;
          if (aggResults.participants.totalCount < maxEndRecordCount) {
            setEndRecordNumber(aggResults.participants.totalCount);
          } else {
            setEndRecordNumber(maxEndRecordCount);
          }
        }
        // if (aggResults.participantInfoData) {
        //   setParticipantInfo(aggResults.participantInfoData);
        // }
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }

  function getClient() {
    return api
      .secureFetch(`Client/Client/${clientId}`)
      .then((response) => {
        if (response && response.data) {
          return { client: response.data };
        }
      })
      .catch(api.catchHandler);
  }

  function getUIFriendlyDate(date) {
    if (!date) return "";
    return dateHelpers.toMDYDateString(date, dateHelpers.YMDHMS);
  }

  function getParticipants(newPageNumber = 1, quickFilters) {
    let payload = {
      MaxResults: 25,
      ActiveOnly: activeOnly,
      SortField: "Name",
      SortDirection: "ASC",
      ClientId: clientId,
      PageNumber: newPageNumber,
    };
    if (quickFilters?.length) {
      _.each(quickFilters, (filter) => {
        if (filter.values && filter.values.length > 0) {
          payload[filter.filterName] = filter.values[0];
        } else {
          payload[filter.filterName] = filter.label || filter.value;
        }
      });
    } else {
      _.each(filters, (filter) => {
        if (filter.values && filter.values.length > 0) {
          payload[filter.filterName] = filter.values[0];
        } else {
          payload[filter.filterName] = filter.label || filter.value;
        }
      });
    }
    saveSearchResultsLocally();

    // api.logData({
    //   eventType: constants.LOG_EVENT_TYPES.PATIENT_SEARCH,
    //   severity: constants.LOG_SEVERITIES.INFO,
    //   message: `Patients searched for ${JSON.stringify(payload)}.`,
    //   clientName: null,
    //   patientId: null,
    //   pHIFlag: false,
    //   resourceList: null
    // });
    return api
      .securePost(`Participant/PaginatedList`, payload)
      .then((response) => {
        if (response && response.data) {
          return { participants: response?.data?.message };
        }
      })
      .catch(api.catchHandler);
  }

  function filterChange(changedFilter) {
    setFilters(filterHelpers.getFilters(filters, changedFilter));
  }

  useEffect(() => {
    saveSearchResultsLocally();
  }, [filters]);

  function buildFilterList(filters) {
    if (!filters || !filters.length) return;
    return (
      <Row className={"mx-0 p-0 mb-0 pb-2 d-flex align-items-center"}>
        {map(filters, (filter) => {
          if (filter.filterName === "ClientId") return;
          let dataset;
          if (filter.filterName === "Gender") {
            dataset = constants.FHIR_GENDER_TYPES;
          }
          if (filter.filterName === "State") {
            dataset = constants.US_STATE_ABBR_OPTIONS;
          }
          return (
            <QuickFilter
              filter={filter}
              dataset={dataset ?? null}
              clearFilter={() => clearFilter(filter.filterName)}
            />
          );
        })}
      </Row>
    );
  }

  function clearFilter(filterName) {
    let newFilterList = filters.slice();
    let filteredItems = reject(
      newFilterList,
      (filter) => filter.filterName === filterName
    );
    setFilters(filteredItems);
  }

  function onFilterKeyDown(event) {
    if (event.which === 13) {
      // enter key
      // delay by the debounce ms period to ensure any debounced entries reflect in the post
      setTimeout(() => refreshData(1), 350);
    }
  }

  return (
    <PageContainer>
      <Col className={"m-0 p-0"}>
        <div className="my-3">
          <LongBreadCrumb client page={"Members"}>
            <Row className={"d-flex space-between"}>
              <PHIHider />
              <StyledButton
                onClick={() =>
                  // setShowNewPatientModal(true)
                  navigate(`/clients/${clientId}/patient-chart/new`)
                }
                className={"d-flex align-items-center mx-4"}
                color="primary"
                icon={BsPersonFill}
              >
                ADD MEMBER
              </StyledButton>
            </Row>
          </LongBreadCrumb>
        </div>
        <Col className={"main-content-container py-2"}>
          <Row className={"mx-0 p-0 mt-2 mb-0 pb-4 d-flex align-items-center"}>
            <Col xs="2">
              <FilterText
                disabled={loading}
                onKeyDown={(event) => onFilterKeyDown(event)}
                filterName="Identifier"
                label="Identifiers"
                onChangeCallback={filterChange}
                debounceTimeout={0}
                value={filterHelpers.getValue("Identifier", filters)}
              />
            </Col>
            <Col xs="3">
              <FilterText
                disabled={loading}
                onKeyDown={(event) => onFilterKeyDown(event)}
                filterName="Name"
                label="Name"
                debounceTimeout={0}
                onChangeCallback={filterChange}
                value={filterHelpers.getValue("Name", filters)}
                // debounceTimeout={350}
              />
            </Col>
            <Col xs="2">
              <FilterSelect
                isDisabled={loading}
                filterName="Gender"
                displayName="Gender"
                options={constants.FHIR_GENDER_TYPES}
                onChangeCallback={filterChange}
                isSingleSelect={true}
                value={_.filter(
                  constants.FHIR_GENDER_TYPES,
                  (x) => x.value === filterHelpers.getValue("Gender", filters)
                )}
              />
            </Col>
            <Col xs="2">
              <FilterSelect
                isDisabled={loading}
                filterName="State"
                displayName="State"
                options={constants.US_STATE_ABBR_OPTIONS}
                onChangeCallback={filterChange}
                isSingleSelect={true}
                value={_.filter(
                  constants.US_STATE_ABBR_OPTIONS,
                  (x) => x.value === filterHelpers.getValue("State", filters)
                )}
              />
            </Col>
            <Col>
              <FilterText
                disabled={loading}
                onKeyDown={(event) => onFilterKeyDown(event)}
                filterName="City"
                label="City"
                debounceTimeout={0}
                onChangeCallback={filterChange}
                // debounceTimeout={350}
                value={filterHelpers.getValue("City", filters)}
              />
            </Col>
            <Col className="pt-3">
              <StyledButton
                color="primary"
                className="float-right mt-2"
                disabled={loading}
                onClick={() => refreshData(1)}
              >
                Find
              </StyledButton>
            </Col>
          </Row>
          {buildFilterList(filters)}
          <Row className={"no-space"}>
            <Table size="sm" responsive striped bordered>
              <thead>
                <tr className="header-colored">
                  <th className={"header-default"}>Person Identifiers</th>
                  <th className={"header-default"}>Name</th>
                  <th className={"header-default"}>Gender</th>
                  <th className={"header-default"}>Open Items</th>
                  <th className={"header-default"}>Conditions</th>
                  <th className={"header-default"}>Coverage</th>
                  <th className={"header-default"}>Address</th>
                  <th className={"header-default"}>Contact Info</th>
                  <th className={"header-default"}>Key Dates</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={9}>
                      <Loader />
                    </td>
                  </tr>
                ) : null}
                {!loading && currentParticipants && currentParticipants.length
                  ? _.map(currentParticipants, (p, index) => {
                      return (
                        <tr
                          key={`patientRow${p.id}${index}`}
                          onClick={() =>
                            navigate(
                              `/clients/${clientId}/patient-chart/${p.clientIdentifierId}`
                            )
                          }
                          className="cursorPointer clickableRow fontSmall"
                        >
                          <StyledTD>
                            {p?.ssn ? (
                              <span>
                                SSN:
                                {!userCtx?.showSsn && p?.ssn.length
                                  ? `*****${p.ssn.substr(p.ssn.length - 4)}`
                                  : p?.ssn}
                                <br />
                              </span>
                            ) : null}
                            {p.eeId && (
                              <>
                                EE:
                                {p.eeId}
                                <br />
                              </>
                            )}
                            {p.wellnecityId && (
                              <>
                                W.ID:
                                {p.wellnecityId}
                                <br />
                              </>
                            )}
                          </StyledTD>
                          <StyledTD>
                            <p>{`${_.capitalize(p.lastName)}, ${_.startCase(
                              _.toLower(p.firstName)
                            )}`}</p>
                            <p>
                              <i>{_.capitalize(p.relation)}</i>
                            </p>
                          </StyledTD>
                          <StyledTD textCenter={true}>
                            {p.gender && p.gender.charAt(0).toUpperCase()}
                          </StyledTD>
                          <StyledTD>
                            {_.map(p.notifications, (op, index) => {
                              return (
                                <div key={`notification${op.id}${index}`}>
                                  <VscWarning className="mr-2" />
                                  {op.comments[op.comments.length - 1].comment}
                                </div>
                              );
                            })}
                            <br />
                          </StyledTD>
                          <StyledTD>
                            {_.chain(p.conditions)
                              .orderBy((x) => (x.isPrimary === true ? 0 : 1))
                              .groupBy((x) => x.isPrimary)
                              .toArray()
                              .map((list, index) => {
                                let isPrimary = list[0]?.isPrimary;
                                let label = isPrimary
                                  ? "Problem List Items"
                                  : "Additional Diagnoses";
                                return (
                                  <div
                                    key={`condition${p.id}${index}`}
                                    className={classNames({
                                      "mb-2": isPrimary,
                                    })}
                                  >
                                    <div className="flex">
                                      {label}
                                      <div>&nbsp;({list?.length})</div>
                                    </div>
                                    {_.chain(list)
                                      .orderBy((x) => x.recordedDate)
                                      .groupBy((x) => x.ailment)
                                      .toArray()
                                      .map((d, idx) => {
                                        let ailment = d[0]?.ailment;
                                        return (
                                          <div
                                            key={`diagnoses${ailment}${idx}`}
                                            className="text-muted"
                                          >
                                            {ailment}
                                          </div>
                                        );
                                      })
                                      .value()}
                                  </div>
                                );
                              })
                              .value()}
                          </StyledTD>
                          <StyledTD>
                            {`Eligibility Status: ${helpers.resolveEligibilityStatus(
                              p?.hireDate,
                              p?.terminationDate
                            )}`}
                            {_.chain(p.coverages)
                              .groupBy((x) => x.name)
                              .toArray()
                              .map((op, i) => {
                                if (
                                  op[0] &&
                                  _.some(
                                    constants.COVERAGE_REJECT_LIST,
                                    (cr) => cr === op[0].name
                                  )
                                )
                                  return;
                                return (
                                  <div key={`coverage${p.id}${i}`}>
                                    {op[0] && op[0].name}
                                    <br />
                                  </div>
                                );
                              })
                              .value()}
                          </StyledTD>
                          <StyledTD>
                            {_.startCase(_.toLower(p.addressLine1))}
                            <>
                              {p.addressLine2 ? (
                                <>
                                  <br />
                                  {_.startCase(_.toLower(p.addressLine2))}
                                </>
                              ) : null}
                            </>
                            <br />
                            {_.startCase(_.toLower(p.city))} {p.state}{" "}
                            {p.zipCode}
                          </StyledTD>
                          <StyledTD>
                            {_.map(p.contactNumbers, (op, i) => {
                              return (
                                <div
                                  key={`phoneNo${p.id}${i}`}
                                  className={
                                    op.isPrimary ? "font-weight-bold" : ""
                                  }
                                >
                                  {op.use ? `(${op.use.charAt(0)})` : ""}{" "}
                                  {helpers.formatPhoneNumber(op.value)}
                                  <br />
                                </div>
                              );
                            })}
                            <br />
                            {p.emails && p.emails.length
                              ? _.map(p.emails, (op, i) => {
                                  return (
                                    <div
                                      key={`email${p.id}${i}`}
                                      className={
                                        op.isPrimary ? "font-weight-bold" : ""
                                      }
                                    >
                                      {`(${
                                        op.use ? op.use.charAt(0) : ""
                                      }) ${op.value.toLowerCase()}`}
                                      <br />
                                    </div>
                                  );
                                })
                              : "Email not provided"}
                            {}
                          </StyledTD>
                          <StyledTD>
                            <>
                              <div>
                                {`Last Contact: ${dateHelpers.toMDYDateString(
                                  p?.lastContactDate,
                                  dateHelpers.YMDHMS
                                )}`}
                              </div>
                              <div>
                                {`Hire: ${getUIFriendlyDate(p?.hireDate)}`}
                              </div>
                              <div>
                                {`Termination: ${getUIFriendlyDate(
                                  p?.terminationDate
                                )}`}
                              </div>
                              <div>
                                {`Coverage Effective: ${getUIFriendlyDate(
                                  p?.medical?.startOn
                                )}`}
                              </div>
                              <div>
                                {`Coverage End: ${getUIFriendlyDate(
                                  p?.medical?.endOn
                                )}`}
                              </div>
                            </>
                          </StyledTD>
                        </tr>
                      );
                    })
                  : null}
                {!loading &&
                currentParticipants &&
                !currentParticipants.length ? (
                  <tr>
                    <td colSpan={9} className="text-left">
                      No participants were found for the provided criteria.
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </Row>
          {!loading && currentParticipants && currentParticipants.length ? (
            <Row className="mt-2">
              <Col>
                <Pagination>
                  {pageNumber > 1 ? (
                    <>
                      <Link
                        linkText="First"
                        callBack={() => setPageNumber(1)}
                      />
                      <Link
                        linkText="Previous"
                        callBack={() => setPageNumber(pageNumber - 1)}
                      />
                    </>
                  ) : null}
                  {totalPageCount > pageNumber ? (
                    <Link
                      linkText="Next"
                      callBack={() => setPageNumber(pageNumber + 1)}
                    />
                  ) : null}
                </Pagination>
              </Col>
              <Col>
                <div className="float-right">
                  Members {startRecordNumber} - {endRecordNumber} of{" "}
                  {totalCount}
                </div>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Col>
    </PageContainer>
  );
}

const QuickFilter = ({ filter, clearFilter, dataset }) => {
  //const [tooltipOpen, setTooltipOpen] = useState(false);
  //const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const actualValue =
    !dataset || dataset.length
      ? _.find(dataset, (d) => d.value === filter.value)?.label ?? ""
      : filter.value;
  return (
    <div className={"quickfilter__container_div"}>
      <Badge className={"quickfilter__badge"}>
        <div className={"d-flex flex-row"}>
          <div className={"quickfilter__clear__div"}>
            <MdClear onClick={clearFilter} id={filter.filterName} />
          </div>
          <div className={"quickfilter__separator__div"} />
          <div className={"quickfilter__value__div"}>
            {filter.filterName}:{" "}
            {dataset
              ? _.find(dataset, (d) => d.value === filter.value)?.label
              : filter.value}
          </div>
        </div>
      </Badge>
      {/*      <Tooltip
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        target={filter.filterName}
      >
        Remove item from filter.
      </Tooltip>
*/}{" "}
    </div>
  );
};