import React, { useEffect, useState, Fragment, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  api,
  helpers,
  constants,
  dateHelpers,
  UserContext,
  formatPhoneField,
} from "../utils";
import { Row, Col, ButtonGroup, Badge, Tooltip } from "reactstrap";
import _ from "lodash";
import {
  Breadcrumbs,
  Loader,
  DoughnutChart,
  PageContainer,
  WidgetCard,
  PatientChartDiagnosis,
  PatientChartRecentLabs,
  PatientChartNotifications,
  LongBreadCrumb,
  PHIHider,
  PatientChartContactLog,
  PatientChartClaims,
  PatientChartCarePlan,
  PatientChartMedications,
} from ".";
import { toast } from "react-toastify";
import axios from "axios";

const { PATIENT_CHART_DASHBOARD_TYPES, CAMPAIGN_STATUSES } = constants;

function getUIFriendlyDate(date) {
  return dateHelpers.toMDYDateString(date, dateHelpers.YMDHMS);
}

function PatientDataRow({ left, right }) {
  return (
    <Row className="p-1 text-left">
      <Col className="col-6">{left}</Col>
      <Col className="col-6">{right}</Col>
    </Row>
  );
}

export default function PatientChartDashboard(props) {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();
  let { type, clientId, participantId } = useParams();
  clientId = clientId ? parseInt(clientId, 10) : null;
  participantId = participantId ? participantId : null;
  type = type
    ? _.find(PATIENT_CHART_DASHBOARD_TYPES, (x) => x.label === type)
    : PATIENT_CHART_DASHBOARD_TYPES.DASHBOARD;

  let childrenType = _.find(
    PATIENT_CHART_DASHBOARD_TYPES,
    (x) => x.value === type.value + 1
  );
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [currentParticipants, setCurrentParticipants] = useState([]);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [loading, setLoading] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [activeOnly, setActiveOnly] = useState(true);
  const [newCase, setNewCase] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [navigateBack, setNavigateBack] = useState(null);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [
    memberHealthCarePlanSpendingInfo,
    setMemberHealthCarePlanSpendingInfo,
  ] = useState([]);
  const [participantInfo, setParticipantInfo] = useState(null);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [labTypeOptions, setLabTypeOptions] = useState([]);
  const [serviceRequestOptions, setServiceRequestOptions] = useState([]);
  const [goalOptions, setGoalOptions] = useState([]);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [primaryConditionOptions, setPrimaryConditionOptions] = useState([]);
  const [observationOptions, setObservationOptions] = useState([]);
  const [practitionerList, setPractitionerList] = useState([]);
  //const [carePlanOptions, setCarePlanOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [refreshAll, setRefreshAll] = useState(false);
  const [medicalCoverage, setMedicalCoverage] = useState({});
  const [dentalCoverage, setDentalCoverage] = useState({});
  const [visionCoverage, setVisionCoverage] = useState({});
  const [lookerURL, setLookerURL] = useState(null);

  useEffect(() => {
    return () => {
      setClient(null);
      setCurrentParticipant(null);
      setClients([]);
      setCurrentParticipants([]);
      setBreadcrumbs([]);
      setNavigateBack(null);
      setSubtitle(null);
      setShowFullScreen(false);
      setNewCase(false);
    };
  }, []);

  // useEffect(() => {
  //   let linkList = [];
  //   setNavigateBack(null);
  //   let baseLink = "/patient/chart/dashboard";
  //
  //   if (clientId && client) {
  //     linkList.push({
  //       link: helpers.buildPatientDashboardLinks(
  //         PATIENT_CHART_DASHBOARD_TYPES.CLIENT,
  //         clientId
  //       ),
  //       label: `${client.name} Participants`,
  //     });
  //     setSubtitle("");
  //     setNavigateBack(baseLink);
  //   }
  //   if (client && participantId && participantInfo) {
  //     linkList.push({
  //       link: helpers.buildPatientDashboardLinks(
  //         PATIENT_CHART_DASHBOARD_TYPES.PARTICIPANT,
  //         client.id,
  //         participantId
  //       ),
  //       label: `Patient - ${_.startCase(_.toLower(participantInfo?.firstName))}
  //       ${_.capitalize(participantInfo?.lastName)}`,
  //     });
  //     setSubtitle("Patient Chart");
  //     setNavigateBack(
  //       helpers.buildPatientDashboardLinks(
  //         PATIENT_CHART_DASHBOARD_TYPES.PARTICIPANT,
  //         participantId
  //       )
  //     );
  //   }
  //   // link from last item since that is the active current url
  //   linkList = _.map(linkList, (item, index) => {
  //     if (index + 1 === linkList.length) {
  //       item.link = null;
  //     }
  //     return item;
  //   });
  //   if (!_.isEqual(linkList, breadcrumbs)) {
  //     setBreadcrumbs(linkList);
  //   }
  // }, [type, clientId, participantId, client, participantInfo]);

  useEffect(refreshData, [clientId, participantId, activeOnly, type, filters]);

  useEffect(() => {
    if (!participantInfo) return;
    api.logData({
      eventType: constants.LOG_EVENT_TYPES.PHI_TOGGLE,
      severity: constants.LOG_SEVERITIES.INFO,
      message: `Patient PHI toggle switched ${userCtx.showSsn ? "on" : "off"}`,
      clientName: null,
      patientId:
        participantInfo && participantInfo.patientReferences?.length
          ? participantInfo.patientReferences[0]
          : null,
      pHIFlag: userCtx.showSsn,
      resourceList: null,
    });
  }, [userCtx.showSsn]);

  useEffect(() => {
    if (!participantInfo) return;
    let patientResourceList = _.map(
      participantInfo.patientReferences,
      (pr) => ({
        fHIRDataSet: "",
        resourceType: "Patient",
        resourceId: pr || null,
      })
    );
    let resourceList = [
      {
        fHIRDataSet: "",
        resourceType: "Person",
        resourceId: participantInfo.id || null,
      },
    ];
    resourceList = resourceList.concat(patientResourceList);
    api.logData({
      eventType: constants.LOG_EVENT_TYPES.VIEW_PATIENT,
      severity: constants.LOG_SEVERITIES.INFO,
      message: `Patient details viewed`,
      clientName: null,
      patientId:
        participantInfo && participantInfo.patientReferences?.length
          ? participantInfo.patientReferences[0]
          : null,
      pHIFlag: userCtx.showSsn,
      resourceList: resourceList,
    });
    // disabled for now, ART, 09-Feb-23
    // let apiCalls = [];
    // apiCalls.push(getLookerInfo());
    // Promise.all(apiCalls)
    //   .then((arrayResults) => {
    //     let aggResults = {};
    //     _.each(arrayResults, (x) => Object.assign(aggResults, x));
    //     if (aggResults.lookerInfoData) {
    //       setLookerURL(aggResults.lookerInfoData);
    //     }
    //   })
    //   .catch(api.catchHandler)
    //   .finally(() => setLoading(false));
  }, [participantInfo]);

  function getPractitionerList() {
    let payload = {
      clientId: clientId,
    };
    return api
      .securePost("Participant/GetPractitioners", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          return { practitionerList: response.data.message };
        }
      })
      .catch(() => {
        console.error("An error occurred while retrieving practitioner list.");
      });
  }

  function getLabTypes() {
    return api
      .secureFetch("FhirAdmin/LabTypes")
      .then((response) => {
        if (response && response.data && response.data.success) {
          let list = _.map(response.data.message, (d) => {
            return {
              ...d,
              value: d.id,
              label: d.name + ": " + d.componentName,
            };
          });
          return { labTypeList: list };
        }
      })
      .catch(api.catchHandler);
  }

  function refreshData() {
    if (!loading) {
      setLoading(true);
      let apiCalls = [];
      if (participantId) {
        apiCalls.push(getParticipantInfo());
        apiCalls.push(getRecentLabReferenceInfo());
        apiCalls.push(getDiagnosisReferenceInfo());
        apiCalls.push(getLabTypes());
        apiCalls.push(getPractitionerList());
        //apiCalls.push(getCarePlanReferenceInfo());
        //apiCalls.push(getPrimaryDiagnosisReferenceInfo());
        //apiCalls.push(getGoalReferenceInfo());
        //apiCalls.push(getRecentReferralReferenceInfo());
        //apiCalls.push(getTaskReferenceInfo());
        // dummy until apis are finished/know where to get data:
        setMemberHealthCarePlanSpendingInfo([
          { label: "Remaining Funds", value: 50 },
          { label: "Medical Spend", value: 30 },
          { label: "Rx Spend", value: 20 },
        ]);
      }
      Promise.all(apiCalls)
        .then((arrayResults) => {
          let aggResults = {};
          _.each(arrayResults, (x) => Object.assign(aggResults, x));
          if (aggResults.clients) {
            setClients(aggResults.clients);
            if (clientId) {
              let clientInfo = _.find(aggResults.clients, (x) => {
                return x.id === clientId;
              });
              setClient(clientInfo);
            }
          }
          if (aggResults.participantInfoData) {
            splitCoverageList(aggResults.participantInfoData.coverages);
            if (aggResults.participantInfoData.coverages) {
              aggResults.participantInfoData.groupedCoverages = _.groupBy(
                aggResults.participantInfoData.coverages,
                (c) => c.name
              );
              aggResults.participantInfoData.coverages = _.reject(
                aggResults.participantInfoData.coverages,
                (c) =>
                  _.some(
                    constants.COVERAGE_REJECT_LIST,
                    (crl) => crl.toLowerCase() === c.name.toLowerCase()
                  )
              );
            }
            setParticipantInfo(aggResults.participantInfoData);
          }
          if (aggResults.participantInfoData?.patientReferences) {
            const patientReferences = _.map(
              aggResults.participantInfoData.patientReferences,
              (r, i) => {
                return {
                  label: `${r} ${_.startCase(
                    _.toLower(aggResults.participantInfoData?.firstName)
                  )} 
              ${_.capitalize(aggResults.participantInfoData?.lastName)}`,
                  value: r,
                };
              }
            );
            console.log(patientReferences);
            setSubjectOptions(patientReferences);
          }
          if (aggResults.recentLabReferenceInfo) {
            setObservationOptions(aggResults.recentLabReferenceInfo);
          }
          if (aggResults.diagnosisReferenceInfo) {
            setConditionOptions(aggResults.diagnosisReferenceInfo);
          }
          if (aggResults.labTypeList) {
            setLabTypeOptions(aggResults.labTypeList);
          }
          if (aggResults.practitionerList) {
            setPractitionerList(
              aggResults.practitionerList
                ? _.map(aggResults.practitionerList, (prac) => {
                    return {
                      ...prac,
                      value: prac.resourceId,
                      label: `${prac.firstName} ${prac.lastName}`,
                    };
                  })
                : []
            );
          }
          // if(aggResults.primaryDiagnosisReferenceInfo) {
          //   setPrimaryConditionOptions(aggResults.primaryDiagnosisReferenceInfo);
          // }
          // if(aggResults.goalReferenceInfo) {
          //   setGoalOptions(aggResults.goalReferenceInfo);
          // }
          // if(aggResults.recentLabReferenceInfo) {
          //   setServiceRequestOptions(aggResults.recentReferralReferenceInfo);
          // }
          // if(aggResults.carePlanReferenceInfo) {
          //   setCarePlanOptions(aggResults.carePlanReferenceInfo);
          // }
          // if(aggResults.taskReferenceInfo) {
          //   setTaskOptions(aggResults.taskReferenceInfo);
          // }
        })
        .catch(api.catchHandler)
        .finally(() => setLoading(false));
    }
  }
  function splitCoverageList(coverages) {
    if (!coverages || !coverages.length) return;
    coverages = _.reject(coverages, (c) =>
      _.some(
        constants.COVERAGE_REJECT_LIST,
        (crl) => crl.toLowerCase() === c.name.toLowerCase()
      )
    );
    let medical, vision, dental;
    [vision, medical] = _.partition(coverages, (c) =>
      _.some(
        constants.VISION_COVERAGE_TYPES,
        (v) => v === (c.name ? c.name.toLowerCase() : "")
      )
    );
    [dental, medical] = _.partition(medical, (c) =>
      _.some(
        constants.DENTAL_COVERAGE_TYPES,
        (v) => v === (c.name ? c.name.toLowerCase() : "")
      )
    );
    medical = _.orderBy(medical, [(x) => Date.parse(x.startOn)], ["desc"]);
    vision = _.orderBy(vision, [(x) => Date.parse(x.startOn)], ["desc"]);
    dental = _.orderBy(dental, [(x) => Date.parse(x.startOn)], ["desc"]);
    setMedicalCoverage(_.find(medical, ["type", "HIP"]));
    setVisionCoverage(_.first(vision));
    setDentalCoverage(_.first(dental));
  }

  function refreshReferenceData() {
    let apiCalls = [];
    if (participantId) {
      apiCalls.push(getRecentLabReferenceInfo());
      apiCalls.push(getDiagnosisReferenceInfo());
      //apiCalls.push(getCarePlanReferenceInfo());
      //apiCalls.push(getPrimaryDiagnosisReferenceInfo());
      //apiCalls.push(getGoalReferenceInfo());
      //apiCalls.push(getRecentReferralReferenceInfo());
      //apiCalls.push(getTaskReferenceInfo());
    }
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));

        if (aggResults.recentLabReferenceInfo) {
          setObservationOptions(aggResults.recentLabReferenceInfo);
        }
        // if(aggResults.primaryDiagnosisReferenceInfo) {
        //   setPrimaryConditionOptions(aggResults.primaryDiagnosisReferenceInfo);
        // }
        if (aggResults.diagnosisReferenceInfo) {
          setConditionOptions(aggResults.diagnosisReferenceInfo);
        }

        // if(aggResults.goalReferenceInfo) {
        //   setGoalOptions(aggResults.goalReferenceInfo);
        // }
        // if(aggResults.recentLabReferenceInfo) {
        //   setServiceRequestOptions(aggResults.recentReferralReferenceInfo);
        // }
        // if(aggResults.carePlanReferenceInfo) {
        //   setCarePlanOptions(aggResults.carePlanReferenceInfo);
        // }
        // if(aggResults.taskReferenceInfo) {
        //   setTaskOptions(aggResults.taskReferenceInfo);
        // }
      })
      .catch(api.catchHandler);
  }

  // function resolveEligibilityStatus(hireDate, terminationDate) {
  //   if(!hireDate && !terminationDate) return;
  //   if(hireDate && !terminationDate) return "Active";
  //   let now = new Date().getTime();
  //   hireDate = Date.parse(dateHelpers.dateTimeFormat(hireDate));
  //   terminationDate = Date.parse(dateHelpers.dateTimeFormat(terminationDate));
  //   let isActive = (now <= terminationDate && now >= hireDate);
  //   return isActive ? "Active" : "Inactive";
  // }

  function getLookerInfo() {
    if (
      !participantInfo ||
      !participantInfo.wellnecityId ||
      !participantInfo.wellnecityId.length
    )
      return;
    let patientIdValue = participantInfo?.wellnecityId || "";
    return api
      .secureFetch(
        `Participant/GetLookerURL/${patientIdValue}`,
        {},
        axiosCancelToken.token
      )
      .then((response) => {
        if (response && response.data && response.data.success) {
          return { lookerInfoData: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function getParticipantInfo() {
    return api
      .secureFetch(`Participant/ParticipantInfo/${clientId}/${participantId}`)
      .then((response) => {
        if (!response) return;
        // if (response.response.status === 404) {
        //   toast.warn("No results found. Returning to search screen.", {
        //     autoClose: false,
        //   });
        //   setTimeout(() => navigate(-1), 2000);
        //   return;
        // }
        if (response.data && response.data.success) {
          console.log("participant info: ", response.data.message);
          return { participantInfoData: response.data.message };
        }
      })
      .catch((err) => {
        console.debug({ err });
        api.catchHandler(err);
      });
  }

  function getRecentLabReferenceInfo() {
    const payload = {
      clientId: clientId,
      clientIdentifierId: participantId,
    };
    return api
      .securePost("Participant/GetRecentLabReferences", payload)
      .then((response) => {
        if (response && response.data) {
          return { recentLabReferenceInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function getDiagnosisReferenceInfo() {
    let payload = {
      clientId: clientId,
      clientIdentifierId: participantId,
      showOnlyPrimaryDiagnoses: false,
    };

    return api
      .securePost("Participant/GetConditionReferences", payload)
      .then((response) => {
        if (response && response.data) {
          return { diagnosisReferenceInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  // function getPrimaryDiagnosisReferenceInfo() {
  //   let payload = {
  //     clientId: clientId,
  //     clientIdentifierId: participantId,
  //     showOnlyPrimaryDiagnoses: true,
  //   }

  //   return api
  //   .securePost("Participant/GetConditionReferences", payload)
  //   .then((response) => {
  //     if (response && response.data) {
  //       return { primaryDiagnosisReferenceInfo: response.data.message };
  //     }
  //   })
  //   .catch(api.catchHandler);
  // }

  // function getGoalReferenceInfo() {
  //   let payload = {
  //     clientId: clientId,
  //     clientIdentifierId: participantId
  //   }

  //   return api
  //   .securePost("Participant/GetGoalReferences", payload)
  //   .then((response) => {
  //     if (response && response.data) {
  //       return { goalReferenceInfo: response.data.message };
  //     }
  //   })
  //   .catch(api.catchHandler);
  // }

  // function getRecentReferralReferenceInfo() {
  //   let payload = {
  //     clientId: clientId,
  //     clientIdentifierId: participantId
  //   }

  //   return api
  //   .securePost("Participant/GetRecentReferralReferences", payload)
  //   .then((response) => {
  //     if (response && response.data) {
  //       return { recentReferralReferenceInfo: response.data.message };
  //     }
  //   })
  //   .catch(api.catchHandler);
  // }

  // function getCarePlanReferenceInfo() {
  //   return api.securePost("Participant/GetCarePlanReferences", {
  //     clientId: clientId,
  //     clientIdentifierId: participantId
  //   }).then((response) => {
  //     if (response && response.data) {
  //       return { carePlanReferenceInfo: response.data.message };
  //     }
  //   })
  //   .catch(api.catchHandler);
  // }

  // function getTaskReferenceInfo() {
  //   let payload = {
  //     clientId: clientId,
  //     clientIdentifierId: participantId
  //   }

  //   return api
  //   .securePost("Participant/GetTaskReferences", payload)
  //   .then((response) => {
  //     if (response && response.data) {
  //       return { taskReferenceInfo: response.data.message };
  //     }
  //   })
  //   .catch(api.catchHandler);
  // }

  let participantFullName = "";
  let pageTitle = "";
  if (participantInfo) {
    participantFullName = `${_.startCase(
      _.toLower(participantInfo.firstName)
    )} ${_.capitalize(participantInfo.lastName)}`;
    pageTitle = participantFullName + " " + participantInfo.wellnecityId;
  }

  function captionedRowData(caption, data) {
    return (
      <Row>
        <Col>
          <b>{caption}</b>
          <p>{data}</p>
        </Col>
      </Row>
    );
  }

  function buildContactNumbers(contactNumbers) {
    if (!contactNumbers || !contactNumbers.length) return "";
    return contactNumbers.map((number, idx) => {
      return <ContactBadge key={`${number.value}-${idx}`} number={number} />;
    });
  }

  return (
    <PageContainer>
      <Breadcrumbs
        list={breadcrumbs}
        rightAreaSize={breadcrumbs?.length >= 3 ? 3 : 4}
        rightArea={
          <Row className="m-0 p-0">
            <Col className="m-0 p-0">
              <ButtonGroup className="float-right">
                {/* {!showHomeScreen && navigateBack && (
                  <StyledButton onClick={goBack} icon={IoReturnUpBackOutline}>
                    Back
                  </StyledButton>
                )} */}
              </ButtonGroup>
            </Col>
          </Row>
        }
        loading={loading}
        subtitle={subtitle}
      />
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Col className={"m-0 p-0"}>
            <div className="my-3">
              <LongBreadCrumb
                context={"Patient Chart"}
                page={pageTitle}
                trailing={<PHIHider />}
              />
            </div>
            <Row className="my-2 p-0 w-100">
              <Col lg="6">
                {/*<WidgetCard*/}
                {/*  title="Member Health Plan Spending"*/}
                {/*  startOpen={false}*/}
                {/*  cardBodyClass="chart-card-body"*/}
                {/*>*/}
                {/*  /!* {memberHealthCarePlanSpendingInfo ? (*/}
                {/*    <DoughnutChart data={memberHealthCarePlanSpendingInfo} usePercentSign />*/}
                {/*  ) : null} *!/*/}
                {/*  {lookerURL && participantInfo ? (*/}
                {/*    <Row>*/}
                {/*      <Col style={{ height: "350px" }}>*/}
                {/*        <iframe*/}
                {/*          width="100%"*/}
                {/*          height="100%"*/}
                {/*          frameborder="0"*/}
                {/*          src={lookerURL}*/}
                {/*        />*/}
                {/*      </Col>*/}
                {/*    </Row>*/}
                {/*  ) : null}*/}
                {/*</WidgetCard>*/}
                <WidgetCard title="Patient Information" startOpen={true}>
                  <Fragment>
                    <PatientDataRow
                      left={captionedRowData("Name", participantFullName)}
                      right={captionedRowData(
                        "Hire Date",
                        getUIFriendlyDate(participantInfo?.hireDate)
                      )}
                    />
                    <PatientDataRow
                      left={captionedRowData(
                        "DOB",
                        getUIFriendlyDate(participantInfo?.birthDate)
                      )}
                      right={captionedRowData(
                        "Employee Termination Date",
                        getUIFriendlyDate(participantInfo?.terminationDate)
                      )}
                    />
                    <PatientDataRow
                      left={captionedRowData(
                        "SSN",
                        !userCtx?.showSsn && participantInfo?.ssn ? (
                          <p>{`*****${participantInfo?.ssn.substr(
                            participantInfo?.ssn.length - 4
                          )}`}</p>
                        ) : (
                          <p>{participantInfo?.ssn}</p>
                        )
                      )}
                      right={captionedRowData(
                        "Medical Coverage Effective Date",
                        getUIFriendlyDate(medicalCoverage?.startOn)
                      )}
                    />
                    <PatientDataRow
                      left={captionedRowData(
                        "Gender",
                        _.capitalize(participantInfo?.gender)
                      )}
                      right={captionedRowData(
                        "Medical Coverage Termination Date",
                        getUIFriendlyDate(medicalCoverage?.endOn)
                      )}
                    />
                    <PatientDataRow
                      left={captionedRowData(
                        "Phone Number(s)",
                        buildContactNumbers(participantInfo?.contactNumbers)
                      )}
                      right={captionedRowData(
                        "Dental Coverage Effective Date",
                        getUIFriendlyDate(dentalCoverage?.startOn)
                      )}
                    />
                    <PatientDataRow
                      left={captionedRowData(
                        "Email",
                        participantInfo?.emails && participantInfo.emails.length
                          ? _.map(participantInfo.emails, (em, idx) => (
                              <div>
                                <div
                                  className={
                                    "d-flex flex-row align-items-center"
                                  }
                                >
                                  <div className={"contact__phone__badge"}>
                                    {em.use
                                      ? `(${em.use.substring(0, 1)})`
                                      : ""}
                                  </div>
                                  <div
                                    className={"contact__separator__badge"}
                                  />
                                  <div className={"contact__phone__value"}>
                                    {em.value ? em.value.toLowerCase() : ""}
                                  </div>
                                </div>
                              </div>
                            ))
                          : ""
                      )}
                      right={captionedRowData(
                        "Dental Coverage Termination Date",
                        getUIFriendlyDate(dentalCoverage?.endOn)
                      )}
                    />
                    <PatientDataRow
                      left={
                        <Row>
                          <Col>
                            <b>Address</b>
                            <p>
                              {participantInfo?.addressLine1 ? (
                                <>
                                  {_.startCase(
                                    _.toLower(participantInfo?.addressLine1)
                                  )}
                                  <br />
                                </>
                              ) : null}
                              {participantInfo?.addressLine2 ? (
                                <>
                                  {_.startCase(
                                    _.toLower(participantInfo?.addressLine2)
                                  )}
                                  <br />
                                </>
                              ) : null}
                              {_.startCase(_.toLower(participantInfo?.city))}{" "}
                              {participantInfo?.state}{" "}
                              {participantInfo?.zipCode}
                            </p>
                          </Col>
                        </Row>
                      }
                      right={captionedRowData(
                        "Vision Coverage Effective Date",
                        getUIFriendlyDate(visionCoverage?.startOn)
                      )}
                    />
                    <PatientDataRow
                      left={captionedRowData(
                        "Relationship",
                        _.capitalize(participantInfo?.relation)
                      )}
                      right={captionedRowData(
                        "Vision Coverage Termination Date",
                        getUIFriendlyDate(visionCoverage?.endOn)
                      )}
                    />
                    <PatientDataRow
                      left={captionedRowData(
                        "Insurance",
                        <p>
                          {_.map(
                            _.keys(participantInfo?.groupedCoverages),
                            (key) => (
                              <div key={key}>{key}</div>
                            )
                          )}
                        </p>
                      )}
                      right={captionedRowData(
                        "Eligibility Status",
                        helpers.resolveEligibilityStatus(
                          participantInfo?.hireDate,
                          participantInfo?.terminationDate
                        )
                      )}
                    />
                    <PatientDataRow
                      left={captionedRowData(
                        "Subscriber Name",
                        participantInfo?.subscriberName
                      )}
                      right={null}
                    />
                    {/* <b>Insurance Effective</b>
                    <p>{participantInfo?.coverages && participantInfo.coverages.length ? 
                      // _.map(participantInfo.coverages, x => getUIFriendlyDate(x.startOn))
                        getUIFriendlyDate(participantInfo.coverages[0].startOn) 
                        : ""}</p> */}
                  </Fragment>
                </WidgetCard>
                <PatientChartContactLog
                  clientId={clientId}
                  participantId={participantId}
                  subjectOptions={subjectOptions}
                  conditionOptions={conditionOptions}
                  observationOptions={observationOptions}
                />
                <PatientChartRecentLabs
                  clientId={clientId}
                  participantId={participantId}
                  subjectOptions={subjectOptions}
                  labTypeOptions={labTypeOptions}
                  refreshReferenceData={refreshReferenceData}
                />
                {/* <PatientChartRecentReferrals
                  clientId={clientId}
                  participantId={participantId}
                  subjectOptions={subjectOptions}
                  carePlanOptions={carePlanOptions}
                  refreshReferenceData={refreshReferenceData}
                /> */}
              </Col>
              <Col lg="6">
                <PatientChartNotifications
                  clientId={clientId}
                  participantId={participantId}
                  subjectOptions={subjectOptions}
                  conditionOptions={conditionOptions}
                  observationOptions={observationOptions}
                  serviceRequestOptions={serviceRequestOptions}
                  refreshReferenceData={refreshReferenceData}
                  refreshAll={refreshAll}
                />
                <PatientChartDiagnosis
                  clientId={clientId}
                  participantId={participantId}
                  subjectOptions={subjectOptions}
                  practitionerList={practitionerList}
                  refreshReferenceData={refreshReferenceData}
                />
                <PatientChartMedications
                  clientId={clientId}
                  participantId={participantId}
                  subjectOptions={subjectOptions}
                  conditionOptions={conditionOptions}
                  observationOptions={observationOptions}
                />
                <PatientChartClaims
                  clientId={clientId}
                  participantId={participantId}
                  subjectOptions={subjectOptions}
                />
                {/*<PatientChartCarePlan*/}
                {/*  clientId={clientId}*/}
                {/*  participantId={participantId}*/}
                {/*  subjectOptions={subjectOptions}*/}
                {/*  goalOptions={goalOptions}*/}
                {/*  taskOptions={taskOptions}*/}
                {/*  serviceRequestOptions={serviceRequestOptions}*/}
                {/*  primaryConditionOptions={primaryConditionOptions}*/}
                {/*  refreshReferenceData={refreshReferenceData}*/}
                {/*  conditionOptions={conditionOptions}*/}
                {/*  observationOptions={observationOptions}*/}
                {/*  refreshAll={refreshAll}*/}
                {/*  setRefreshAll={setRefreshAll}*/}
                {/*/>*/}
              </Col>
            </Row>
          </Col>
        </Fragment>
      )}
    </PageContainer>
  );
}

const ContactBadge = (props) => {
  const [tooltipOpen, setToolTipOpen] = useState(false);
  const { number, tooltip } = props;
  const toggleTooltip = () => setToolTipOpen(!tooltipOpen);
  const keyId = `${number.use}-badge`;
  return (
    <div>
      <div className={"d-flex flex-row align-items-center"}>
        <div className={"contact__phone__badge"}>
          ({number.use.substring(0, 1)})
        </div>
        <div className={"contact__separator__badge"} />
        <div className={"contact__phone__value"}>{number.value}</div>
      </div>
    </div>
  );
};